.dashboard-layout-container {
    background: var(--gray-6, #f2f2f2);
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    width: 100%;
    font-family: 'Avenir';
    font-weight: 800;
  }
  .dashboard-layout-inner-wrapper {
    overflow: hidden;
    padding: 0px 70px;
    width: 100%
  }
  .dashboard-layout-children {
    width: 100%;
    font-family: 'Avenir';
    font-weight: 900;
  }
  