.login-form-container {
    padding: 20px;
    width: 100%;
    height: 100%;
    font-family: 'Avenir';
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  
  .login-form-inner-container{
    width: 20%;

  }
  
    .login-form-header {
       
      h3 {
        font-style: normal;
        font-weight: 800;
        font-size: 30px;
        font-family: 'Avenir';
        line-height: 45px;
        text-align: center;
        color: #333333;
      }
  
      p {
        font-style: normal;
        font-weight: 500;
        font-family: 'Avenir';
        font-size: 16px;
        line-height: 22px;
        text-align: center;
        color: #666565;
      }
    }
  
  
  
    .login-form-input-container {
        display: flex;
        flex-direction: column;
        gap: 1rem;
      margin-top: 20px;
      width: 100%;
        
    }
    
    .input-column{
      display: flex;
      
      img{
        
        width: 30px;
        height: 30px;
        cursor: pointer;
                  
      }
    }
   
  
    .login-form-button-container {
      margin-top: 30px;
    }
    .have-account {
      margin-top: 10px;
      small {
        color: var(--Gray-2, #4f4f4f);
        font-family: 'Avenir-black';
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 21px;
      }
      .nav-link {
        margin-left: 10px;
        font-style: normal;
        font-weight: 800;
        color: #215543;
        font-family: 'Avenir';
        font-size: 18px;
        line-height: 21px;
      }
    }
  }
  