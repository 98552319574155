.nav-item-container {
  cursor: pointer;
  display: flex;
    align-items: center;
  /* Vertically centers children (image and text) */
  font-family: 'Avenir';
  padding: 10px;
  border-radius: 8px;
  margin-right: 8px;
  background: #f2f2f2;
  }

.nav-item-container img {
  margin-right: 10px;
  /* Spacing between image and text */
  width: 24px;
    /* Example size */
    height: 24px;
    /* Example size */
}

.nav-item-container h6 {
  color: #414141;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 1.5;
  /* Ensures proper vertical alignment */
    margin: 0;
    /* Removes default margin */
    display: flex;
    /* Flex ensures the text aligns properly */
    align-items: center;
    /* Centers text vertically if needed */
  }

.nav-item-container:hover,
.isActive {
  background: #f4e5d8;
  transition: 0.4s;
}

.nav-item-container:hover h6,
.isActive h6 {
  color: #b17f34 !important;
  font-weight: 500 !important;
}