.dashboard-container {
  
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  
    margin-left: auto;
    margin-right: auto;
    font-family: 'Avenir';
  }
  