:global {
  .ant-select-selector {
    background: var(--gray-6, #f2f2f2);
    min-height: 50px !important;
    max-height: 200px !important;
    padding: 2px !important;
    overflow: none !important;
    box-sizing: border-box !important;
    display: flex;
    align-items: center;
  }

  .ant-select-selection-search-input {
    height: 100%!important;
      padding: 0px 10px !important;
  }

  .ant-select-selection-placeholder {
    line-height: 50px;
  
    /* Ensure placeholder text is centered */
  }

  .ant-select-selection-item {
      padding: 0px 10px !important;
    font-size: 14px !important;
    line-height: 24px !important;
    text-align: left !important;
    line-height: unset !important;
  }

}

.custom-select-label {
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  color: #6a6a6a;
}