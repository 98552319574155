.root {
  width: 828px;
  padding: 40px 63px;
  font-family: "Avenir";
  @media screen and (max-width: 900px) {
    width: 90%;
  }
  .text {
    h1 {
      font-size: 20px;
      font-weight: 900;
      font-family: "Avenir";
      color: var(--gray-1, #333);
      margin-bottom: 6px;
    }
    p {
      font-size: 16px;
      font-weight: 400;
      font-family: "Avenir";
      line-height: 24px; /* 150% */
      color: var(--gray-2, #4f4f4f);
      width: 50%;
    }
  }
}

.rootx{
  
  width: 500px;
  padding: 40px 63px;
  overflow:hidden;
  font-family: "Avenir";
  @media screen and (max-width: 900px) {
    width: 90%;
  }
  .text {
    h1 {
      font-size: 20px;
      font-weight: 900;
      font-family: "Avenir";
      color: var(--gray-1, #333);
      margin-bottom: 6px;
    }
    p {
      font-size: 16px;
      font-weight: 400;
      font-family: "Avenir";
      line-height: 24px; /* 150% */
      color: var(--gray-2, #4f4f4f);
     
    }
  }
  form{
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
}

.form {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 25px;
  flex-wrap: wrap;
  justify-content: space-between;
}

.inputWidth {
  width: 325px;
}

.phoneNumberInput {
  margin-top: 17px;
  display: flex;
  flex-direction: column;
  .inputField {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  input {
    background: var(--mobile-whites-gray-white, #f2f2f2);
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    width: 325px;
    height: 45px;
    padding: 16px;
    border: none;
    font-size: 17px;
    font-weight: 500;
    font-family: "Avenir";
    line-height: 21px;
    color: var(--gray-2, #4f4f4f);
  }
  input:focus {
    outline: none;
  }
  label {
    font-size: 15px;
    font-weight: 500;
    line-height: 22.5px;
    color: var(--gray-3, #828282);
  }
  select {
    background: #e0e0e0;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    outline: none;
    border: none;
    padding: 5px;
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    padding-right: 16px;
    background-image: none;
    width: 60px !important;
    height: 45px !important;
    color: var(--gray-2, #4f4f4f);
    font-size: 15px;
    font-weight: 500;
    line-height: 21px;
  }
}

.inviteButton {
  width: 100%;
  max-width: 325px;
  background-color: #fd9c42;
  cursor: pointer;
  color: #fff;
  border: none;
  font-family: "Avenir";
  border-radius: 5px;
  transition: 0.5s;
  height: 50px;
  margin-top: 35px;
}

.inviteButton:hover {
  opacity: 0.8;
}

// SUCCESS MODAL

.successRoot {
  width: 333px;
  height: 368px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  h1 {
    text-align: center;
    font-size: 20px;
    font-weight: 800;
    font-family: "Avenir";
    margin-top: 25px;
    margin-bottom: 15px;
    color: var(--gray-1, #333);
  }
  span {
    text-align: center;
    font-size: 16px;
    padding-bottom: 42px;
    font-weight: 400;
    line-height: 24px;
    color: var(--gray-2, #4f4f4f);
  }
}

.arrowBtn{
   margin-bottom: 20px;

  
   img{
    cursor: pointer;
   }
}


