.root {
  width: 828px;
  padding: 40px 63px;
  font-family: "Avenir";

  @media screen and (max-width: 900px) {
    width: 90%;
  }

  .text {
    h1 {
      font-size: 20px;
      font-weight: 900;
      font-family: "Avenir";
      color: var(--gray-1, #333);
      margin-bottom: 6px;
    }

    p {
      font-size: 16px;
      font-weight: 400;
      font-family: "Avenir";
      line-height: 24px;
      /* 150% */
      color: var(--gray-2, #4f4f4f);
      width: 50%;
    }
  }
}

.time {
  font-size: 16px;
  font-weight: 900;
  color: var(--gray-1, #333);
  margin-bottom: 5px;
}

.rootwrap {
  flex-shrink: 0;
  width: 720px;
  padding: 5px 30px;
  font-family: "Avenir";

  .header {
    h1 {

      font-size: 16px;
      font-weight: 900;
      color: var(--gray-1, #333);

    }

    span {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      color: var(--gray-2, #4f4f4f);
    }
  }
}

.rootx {

  width: 700px;
  padding: 10px;
  overflow: hidden;
  font-family: "Avenir";

  .appointmentStepOneContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 10px;

  }

  @media screen and (max-width: 900px) {
    width: 90%;
  }

  .text {
    width: 48%;


    h1 {
      font-size: 20px;
      font-weight: 900;
      font-family: "Avenir";
      color: var(--gray-1, #333);
      margin-bottom: 6px;
    }

    p {
      font-size: 16px;
      font-weight: 400;
      font-family: "Avenir";
      line-height: 24px;
      /* 150% */
      color: var(--gray-2, #4f4f4f);

    }
  }

  form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
}

.form {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 25px;
  flex-wrap: wrap;
  justify-content: space-between;
}

label {
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  color: #6a6a6a;
}

.inputWidth {
  width: 325px;
}

.phoneNumberInput {
  margin-top: 17px;
  display: flex;
  flex-direction: column;

  .inputField {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  input {
    background: var(--mobile-whites-gray-white, #f2f2f2);
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    width: 325px;
    height: 45px;
    padding: 16px;
    border: none;
    font-size: 17px;
    font-weight: 500;
    font-family: "Avenir";
    line-height: 21px;
    color: var(--gray-2, #4f4f4f);
  }

  input:focus {
    outline: none;
  }



  select {
    background: #e0e0e0;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    outline: none;
    border: none;
    padding: 5px;
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    padding-right: 16px;
    background-image: none;
    width: 60px !important;
    height: 45px !important;
    color: var(--gray-2, #4f4f4f);
    font-size: 15px;
    font-weight: 500;
    line-height: 21px;
  }
}

.inviteButton {
  width: 100%;
  max-width: 325px;
  background-color: #fd9c42;
  cursor: pointer;
  color: #fff;
  border: none;
  font-family: "Avenir";
  border-radius: 5px;
  transition: 0.5s;
  height: 50px;
  margin-top: 35px;
}

.inviteButton:hover {
  opacity: 0.8;
}

// SUCCESS MODAL

.successRoot {
  width: 333px;
  height: 368px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h1 {
    text-align: center;
    font-size: 20px;
    font-weight: 800;
    font-family: "Avenir";
    margin-top: 25px;
    margin-bottom: 15px;
    color: var(--gray-1, #333);
  }

  span {
    text-align: center;
    font-size: 16px;
    padding-bottom: 42px;
    font-weight: 400;
    line-height: 24px;
    color: var(--gray-2, #4f4f4f);
  }
}

.meetingLink {
  margin-top: 15px;
  margin-bottom: 15px;
  width: 100%;

  input {
    width: 100% !important;
    padding: 10px;
  }
}

.arrowBtn {
  margin-bottom: 20px;

  cursor: pointer;
}

.calendar {
  margin-right: 20px;

  span {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: var(--gray-2, #4f4f4f);
  }
}

.appointment {
  width: 100%;
  justify-content: space-between;
  display: flex;
  height: max-content;
  flex-direction: row;
}

.details {
  margin-top: 5px;
  margin-bottom: 5px;
  background: var(--mobile-whites-gray-white, #f2f2f2);
  border-radius: 5px;
  display: flex;
  width: 100%;
  padding: 16px 32px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;

  h1 {
    font-size: 18px;
    font-weight: 900;
    color: var(--gray-1, #333);
  }

  a {
    font-size: 16px;
    font-weight: 500;
    text-decoration-line: underline;
    color: var(--gray-2, #4f4f4f);
  }

  p {
    font-size: 16px;
    font-weight: 900;
    color: var(--dark-brown, #6a451a);
  }
}

//global
:global {

  
  .ant-picker-time-panel {
    background-color: #fff !important;
    
  }

     .ant-picker-time-panel-cell-inner {
       color: white !important;
       background-color: #a5753c !important;
       margin-bottom: 2px !important;
     }


  .ant-steps-item-title::after {
    background-color: #f0a500 !important;
  }

  .ant-steps-item-icon,
  .ant-steps-item-tail {
    background-color: #f0a500 !important;

    border-color: #fff !important;
  }

  .ant-steps-finish-icon,
  .ant-steps-icon {
    color: #fff !important;

  }

  .ant-picker-ok {
    button {
      background-color: #8c6533 !important;
      color:#fff !important;
    }
  }

  // Assuming the class name for the selected date fill is `ant-picker-calendar-selected-day`
  .ant-picker-cell-selected,
  .ant-picker-time-panel-cell-selected,
  .ant-picker-cell-range-start-single,
  .ant-picker-cell-range-start,
  .ant-picker-cell-range-end {

    .ant-picker-cell-inner,
    .ant-picker-time-panel-cell-inner {
      background-color: #704c28 !important;
      color: #fff !important;
    }

  }

  .ant-picker-cell-today {
    .ant-picker-cell-inner::before {
      border: 1px solid #f0a500 !important;
    }

  }

  // Customizing the hover effect
  .ant-picker-calendar-cell:hover {
    background-color: #e5e5e5 !important; // Replace with your desired hover color
  }

  // Remove the default blue color focus ring
  .ant-picker-calendar-cell:focus {
    outline: none;
  }
}

.actions {

  width: 20%;
  // display: flex;
  // justify-content: flex-end;
  align-items: center;

  button {
    width: 100%;

  }

  .actionsInnerContainer {
    display: flex;
    margin-top: 10px;

    .btnPrev {
      margin-right: 10px;
    }
  }
}

.dateContainer {
  display: flex;
  margin-top: 10px;
  justify-content: space-between;

  div {
    width: 48%;
  }
}