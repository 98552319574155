td {
    text-align: left !important;
}

button {
    margin: 2px !important;
}

.tableRow:nth-child(odd) {
    background-color: #FCFAF6 !important;
}
