.root {
  background: #fff;
  width: 100%;
  margin-top: 34px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  .right {
  
      // background-color: red;
      h4 {
        margin-top: 10px;
        color: #6a451a;
        text-align: left;
      }
  
      .headerText {
        font-size: 16px;
        padding: 0px 20px;
        color: #6a451a;
        margin-top: 20px;
  
      }
  
      .rightHeader {
        display: flex;
        flex-direction: row;
        padding: 15px 20px;
        border-bottom: 1px solid #e6e6e6;
  
        align-items: center;
  
        h3 {
          width: 141px;
          font-size: 12px;
          font-weight: 900;
          color: var(--gray-3, #828282);
        }
      }
  
  
  
      .history {
        display: flex;
        flex-direction: row;
        margin: 15px 20px;
        padding-bottom: 22px;
        border-bottom: 1px solid #e6e6e6;
        align-items: center;
  
  
        h4 {
          font-size: 14px;
          width: 141px;
  
          font-weight: 500;
          line-height: 150%;
          color: var(--gray-1, #333);
        }
  
        button {
          border: none;
          color: var(--dark-brown, #6a451a);
          font-feature-settings: 'clig' off, 'liga' off;
          font-size: 14px;
          font-weight: 900;
          line-height: 150%;
          background: none;
          cursor: pointer;
          font-variant-numeric: lining-nums tabular-nums;
          text-decoration-line: underline;
        }
      }
    }
  .headerLeft, .headerRight {
    display: flex;
    justify-content: space-between;
    padding: 21px 50px;
    height: 62px;
    border-bottom: 1px solid #e6e6e6;

    h1 {
      font-size: 18px;
      font-weight: 900;
      color: var(--gray-1, #333);
      margin: 0;
    }
  }
  .subHeader {
    padding: 10px 20px;
    font-size: 18px;
    font-weight: 900;
    color: #b17f34;
    margin: 0;
  }
  .left {
    width: 58%;
    border-right: 1px solid #e6e6e6;
  }
  .right {
    width: 42%;
  }
}
.addressInfo {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.street {
  padding: 0px 20px;
  margin-bottom: 10px;
  font-weight: normal;
 
}

.information {
  padding: 0px 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  .info:nth-child(odd) {
    width: 45%;
  }
  .info:nth-child(even) {
    width: 45%;
  }
  .info {
    margin-bottom: 32px;
    span {
      font-size: 12px;
      font-weight: 500;
      text-transform: uppercase;
      color: var(--gray-2, #4f4f4f);
    }
    p {
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      color: var(--gray-1, #333);
    }
  }
}



.creator {
  padding: 5px 20px;
  display: flex;
  flex-direction: column;
  .nameContainer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: #f4f7ff;
      padding: 20px 26px;
      width: 100%;
      flex-shrink: 0;

      border-radius: 4px;
        margin-top: 6px;
  }

  .name {
    height: 76px;
  
    p {
      font-size: 16px;
      font-weight: 900;
      color: var(--gray-1, #333);
    }
    span {
      font-size: 12px;
      font-weight: 500;
      line-height: 21px;
      text-transform: uppercase;
      color: #6a451a
    }
  }
}

.viewCarePlanBtn {
  align-self: flex-end;
  button {
    background-color: black;
    margin-top: 10px;
    height: 45px;
    width: 80px;
    font-size: 15px;
    font-weight: 500;
    padding: 2px;
    cursor: pointer;
  }
}

.addButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  img {
    width: 24px;
    height: 24px;
    margin-right: 4px;
  }
  h2 {
    font-size: 14px;
    font-weight: 900;
    line-height: 150%;
    color: var(--dark-brown, #6a451a);
  }
}
.addBttn{
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
}
.teamMembers {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  .member {
    width: 100%;
    display: flex;
    padding: 16px 50px;
    margin: 0;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    .memberInfo {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    .memberImage {
    
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      align-items: center;
        width: 50%;
      img {
        width: 40px;
        height: 40px;
        margin-right: 16px;
        border-radius: 50%;
      }
      h3 {
        font-size: 15px;
        margin-bottom: 1px;
        font-weight: 900;
        color: var(--gray-1, #333);
      }
      span {
        font-size: 13px;
        font-weight: 500;
        line-height: 24px;
        color: var(--gray-2, #4f4f4f);
      }
    }
  }
  .viewButton {
    cursor: pointer;
    font-size: 14px;
    font-weight: 900;
    text-decoration-line: underline;
    color: var(--dark-brown, #6a451a);
  }
}
.remindPatientModalContainer {
  p {
    font-size: 16px;
    font-weight: 500;
    font-family: "Avenir";
    margin-bottom: 10px;
  }

  padding: 10px 18px;
}
.modalContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 410px;
  padding: 40px 38px;
  height: 366px;
  font-family: "Avenir";
  .modalHeader {
    margin-bottom: 38px;
    h1 {
      font-size: 18px;
      font-weight: 900;
      font-family: "Avenir";
      color: var(--gray-1, #333);
      margin: 0;
    }
    span {
      font-size: 16px;
      font-weight: 400;
      font-family: "Avenir";
      line-height: 24px;
      color: var(--gray-2, #4f4f4f);
    }

  }
  form {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    .modalInput {
      width: 100%;
      margin-bottom: 30px;
    }
    button {
      font-size: 16px;
      font-weight: 900;
      font-family: "Avenir";
      color: #fff;
      border: none;
      height: 45px;
      width: 100%;
      background: var(--mobile-primary-color-default-color, #fd9c42);
      border-radius: 6px;
    }
  }
}
// detailsCareTeam.module.scss

.editableInput {
  box-sizing: border-box;
  padding: 8px; 
  border: 1px solid #ccc; 
  border-radius: 5px; 
  width: 50%; 
  font-size: 16px; 
  margin-top: 4px; 
  margin-left: 10px;
  background-color: #fff; 
  color: #333; 
}

.editableInput:focus {
  border-color: #007bff; 
  outline: none;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}
