.root {

  margin-left: auto;
  margin-top: 100px;
  padding-bottom: 40px;
  margin-right: auto;
  .header {
    font-size: 22px;
    font-weight: 800;
    line-height: 21px;
    color: #6a451a;
  }
  .options {
    display: flex;
    flex-direction: row;
    margin: 23px 0 27px;
  }
  .searchContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    background: #fff;
    height: 38px;
    padding: 10px 20px;
    border-radius: 4px;
    fill: #fff;
    filter: drop-shadow(0px 6px 58px rgba(196, 203, 214, 0.1));
    max-width: 395px;
    input {
      width: 370px;
      border: none;
      font-size: 16px;
      font-weight: 500;
      color: var(--gray-3, #828282);
    }
    img {
      width: 18px;
      margin-right: 12px;
    }
    input:focus {
      outline: none;
    }
  }
  .filter {
    display: flex;
    flex-direction: row;
    background: #fff;
    align-items: center;
    width: 117px;
    padding: 8px 18px;
    border-radius: 4px;
    cursor: pointer;
    height: 40px;
    margin-left: 22px;
    span {
      font-size: 16px;
      font-weight: 500;
      color: var(--gray-3, #828282);
    }
    img {
      width: 24px;
      height: 20px;
      margin-left: 20px;
    }
  }
}

.filterToggle{
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  
}
.filterBtn{
  display: flex;
  
  gap: 1rem;
  button{
    background-color: #FD9C42;
    display: flex;
    align-self: center;
    border-radius: 4px;
    cursor: pointer;
    transition: 0.3s;
    padding: 12px 40px;
    border: none;
    color: #fff;
  }
}




  
.datePickerContainer {
  .react-date-picker,
  .react-date-picker *,
  .react-date-picker *:before,
  .react-date-picker *:after {
    box-sizing: border-box;
    padding: 4px;
    border-radius: 5px;
    border: 1px solid #ccc;
    background-color: #f9f9f9; 
  }
 
  .react-date-picker__wrapper {
    border-radius: 1px solid green;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 5px;
    background-color: #f9f9f9;
  }

  .react-date-picker__inputGroup {
    border: 1px solid red;
    font-size: 14px;
    color: #333;
  }

  .react-date-picker__clear-button {
    svg {
      stroke: #333;
    }
  }

  .react-date-picker__calendar {
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #fff;
  }
}




.filterContainer {
  position: relative;
  display: flex;
 
  width: 100%;
  .filterDropdown {
    position: absolute;
   
    top: 10px;
    left: 120px;
    width: 350px;
    
    background: var(--white, #fff);
    box-shadow: 0px 2px 4px 0px rgba(44, 73, 137, 0.06);
    padding: 29px 24px;
    border-radius: 4px;
   
    h1 {
      font-size: 14px;
      font-weight: 800;
      line-height: 150%;
      color: var(--gray-1, #333);
    }
  }
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    
    li {
      padding: 10px;
      cursor: pointer;
      
      &:hover {
        background-color: #f0f0f0; 
      }
    }
  }
}

.providerList {
  list-style: none;
  padding: 0;
  margin: 0;
}

.providerItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 0;
}

.providerDetails {

  cursor: pointer;
}

.select {
  height: 40px;
  flex-shrink: 0;
  width: 238px;
  border: 1px solid var(--line-grey, #dde1e9);
  background: var(--white, #fff);
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
  color: var(--gray-3, #828282);
  padding: 11px 16px;
  appearance: none; /* Remove default arrow on modern browsers */
  -webkit-appearance: none; /* Remove default arrow on Safari */
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%234F5877' viewBox='0 0 24 24' stroke='%234F5877'%3e%3cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M19 9l-7 7-7-7'%3e%3c/path%3e%3c/svg%3e"); /* Custom arrow icon with color */
  background-position: right 15px center;
  background-repeat: no-repeat;
  background-size: 25px;
  margin-top: 6px;
  margin-bottom: 30px;
}
.select:focus {
  outline: none;
}
.statusFilter {
  display: flex;
  flex-direction: column;
  margin-top: 60px;
}

.progress,
.success,
.failed {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;
  width: 100%;
  p {
    width: 110px;
    height: 35px;
    border-radius: 3px;
    padding-top: 6px;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
  }
}

.progress {
  p {
    color: var(--mobile-primary-color-default-color, #fd9c42);
    background: var(--mobile-primary-color-default-color, #fff6ed);
  }
}

.success {
  p {
    color: #27ae60;
    background-color: #eaf7f0;
  }
}

.failed {
  p {
    background-color: #fdefef;
    color: #eb5757;
  }
}




.filterButtons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  margin-top: 52px;
 
  button {
    display: flex;
    align-self: center;
    border-radius: 4px;
    cursor: pointer;
    transition: 0.3s;
    padding: 12px 40px;
    border: none;
    background: var(--mobile-primary-color-default-color, #fd9c42);
    color: #fff;
  }
}
// .filterButtons button:first-child {
//   text-align: center;
//   font-size: 16px;
//   font-weight: 500;
//   line-height: 150%;
//   color: var(--dark-brown, #6a451a);
//   border: 1.5px solid transparent;
//   margin-right: 10px;
//   background: transparent;
// }

// .filterButtons button:first-child:hover {
//   border: 1.5px solid #6a451a;
// }

// .filterButtons button:last-child {
//   background: var(--mobile-primary-color-default-color, #fd9c42);
//   border-radius: 4px;
//   color: #fff;
//   border: 1.5px solid #fd9c42;
// }

// .filterButtons button:last-child:hover {
//   background: var(--mobile-primary-color-default-color, #fd9c42);
//   color: #fd9c42;
// }

.date {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;

}
.datepicker{
  width: 80%;
  padding: 5px;
}
.pagination{
  button{
    background-color: #FD9C42;
   
    border-radius: 4px;
    cursor: pointer;
    transition: 0.3s;
    
    border: none;
    color: #fff;
  }
}
.searchContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #fff;
  height: 38px;
  padding: 10px 20px;
  border-radius: 4px;
  fill: #fff;
  filter: drop-shadow(0px 6px 58px rgba(196, 203, 214, 0.1));
  max-width: 395px;

  input {
    width: 370px;
    border: none;
    font-size: 16px;
    font-weight: 500;
    color: var(--gray-3, #828282);
  }

  img {
    width: 18px;
    margin-right: 12px;
  }

  input:focus {
    outline: none;
  }
}