.floating-input {
    position: relative;
    width: 100%;
  
    input {
      padding: 10px;
      font-size: 16px;
      border: 1px solid #bdbdbd;
      background-color: #f2f2f2;
      border-radius: 5px;
      outline: none;
      box-sizing: border-box;
      width: 100%;
    }
  
    label {
      position: absolute;
      top: -12px;
      left: 10px;
      font-size: 14px;
      color: #999;
      background-color: #f2f2f2;
      pointer-events: none;
      transition: all 0.3s;
    }
    .password-toggle {
      position: absolute;
      top: 50%;
      right: 10px;
      transform: translateY(-50%);
      cursor: pointer;
      width: 20px; 
      height: auto; 
      fill: #999; 
      transition: all 0.3s;
    }
    
    .password-toggle:hover {
      fill: #555; 
    }
  
  }
  .error {
    input {
      border-color: red;
    }
  }
  