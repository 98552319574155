.custom-table {
    margin-top: 25px;
    background-color: #fff;
    padding: 43px;
    box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.1);
    border-radius: 10px;
    font-family: 'Avenir';
    table {
      font-family: 'Avenir ';
  
      width: 100%;
      border-collapse: collapse;
      margin-top: 17px;
      thead {
        padding-top: 43px;
      }
    }
    .skeleton {
      margin-bottom: 20px;
    }
  }
  
  th,
  td {
    padding: 12px;
    // border-bottom: 1px solid #ddd;
  }
  .table-head {
    width: 141px;
    height: 21px;
    padding-top: 40px;
    padding-bottom: 0px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    th {
      font-size: 12px;
      font-weight: 900;
      color: var(--gray-3, #828282);
      text-transform: uppercase;
    }
  }
  
  td {
    max-width: 200px;
  }
  
  .table-row {
    td {
      color: #656565;
      font-size: 16px;
      font-family: 'Avenir';
      text-align: center;
      border-bottom: 1px solid #ddd;
      font-weight: 400;
    }
  }
  
  .custom-table-image {
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: start !important;
    img {
      width: 45px;
      display: inline-block;
      height: 45px;
      border-radius: 50%;
      object-fit: cover;
      margin-right: 50px;
    }
    p {
      text-align: left;
      display: inline;
      font-size: 16px;
      font-weight: 800;
      font-family: 'Avenir';
      color: #656565;
    }
  }
  
  .table-details-button {
    display: inline-flex;
    height: 40px;
    border-radius: 6px;
    border: none;
    margin: 10px;
    background: var(--mobile-primary-color-brown, #b17f34);
    box-shadow: 0px 6px 12px 0px rgba(63, 140, 255, 0.26);
    padding: 8px 16px;
    justify-content: center;
    cursor: pointer;
    align-items: center;
    gap: 8px;
    color: #fff;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    font-family: 'Avenir';
  }

  .reschedule-button{
    display: inline-flex;
    height: 40px;
    border-radius: 6px;
    border: none;
    background: var(--mobile-primary-color-brown, #b17f34);
    box-shadow: 0px 6px 12px 0px rgba(63, 140, 255, 0.26);
    padding: 8px 16px;
    justify-content: center;
    cursor: pointer;
    align-items: center;
    gap: 8px;
    color: #fff;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    font-family: 'Avenir';
  }
  
  .table-row:nth-child(odd) {
    background-color: #fcfaf7;
  }
  
  .custom-table-top-tabs {
    border-bottom: 1px solid #e0e0e0;
    margin-bottom: 10px;
    &-items {
      display: flex;
      flex-direction: row;
      gap: 69px;
      span {
        font-size: 16px;
        transition: 0.3s;
        font-weight: 900;
        font-family: 'Avenir';
        padding-bottom: 14px;
        cursor: pointer;
        color: var(--gray-3, #828282);
        border-bottom-width: 6px;
        border-bottom: 4px solid transparent;
      }
      .tabs-active {
        border-bottom-color: #fd9c42;
        font-size: 16px;
        font-family: 'Avenir';
        border-bottom-left-radius: 3px;
        border-bottom-right-radius: 3px;
        font-weight: 900;
        color: var(--gray-1, #333);
      }
    }
  }
  
  .paginationContainer {
    margin: 40px 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    span {
      padding: 5px 10px;
      cursor: pointer;
      font-size: 16px;
      font-family: 'Avenir';
      background: transparent;
      transition: 0.3s;
      color: #656565;
      margin-right: 20px;
      border-radius: 4px;
    }
    span:hover {
      background: #fd9c42;
      color: #fff;
    }
    .active {
      background: #fd9c42;
      color: #fff;
    }
  }
  