.type {
    font-size: 14px;
    color: #4F4F4F;
}

hr {
    border: 1px solid #818181;
}
.form-select {
    height: 30px;
    background-color: #818181;
}