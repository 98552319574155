.root {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 32px;
    width: 100%;
    justify-content: space-between;
  }
  .left,
  .right {
    height: 450px;
    flex-shrink: 0;
    width: 49%;
    background: #fff;
    overflow-y: scroll;
    box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.1);
    border-radius: 8px;
    .header {
      display: flex;
      padding: 22px 50px;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #e6e6e6;
      h1 {
        font-size: 18px;
        font-weight: 900;
        color: var(--gray-1, #333);
      }
    }
    .addButton {
      display: flex;
      flex-direction: row;
      align-items: center;
      cursor: pointer;
      img {
        width: 24px;
        height: 24px;
        margin-right: 4px;
      }
      h2 {
        font-size: 14px;
        font-weight: 900;
        line-height: 150%;
        color: var(--dark-brown, #6a451a);
      }
    }
  }
  
  .teamMembers {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    .member {
      width: 100%;
      display: flex;
      padding: 16px 50px;
      margin: 0;
      justify-content: space-between;
      flex-direction: row;
      align-items: center;
      .memberImage {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        img {
          width: 24px;
          height: 24px;
          margin-right: 4px;
        }
        h3 {
          font-size: 15px;
          margin-bottom: 1px;
          font-weight: 900;
          color: var(--gray-1, #333);
        }
        span {
          font-size: 13px;
          font-weight: 500;
          line-height: 24px;
          color: var(--gray-2, #4f4f4f);
        }
      }
    }
    .viewButton {
      cursor: pointer;
      font-size: 14px;
      font-weight: 900;
     
      text-decoration-line: underline;
      color: var(--dark-brown, #6a451a);
    }
  }
  
  .notes {
    width: 100%;
    height: 100%;
   
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 50px;
    .note {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #e6e6e6;
      padding: 22px 0;
      width: 100%;
      .viewNote {
        font-size: 15px;
        font-weight: 900;
        line-height: 150%;
        text-decoration-line: underline;
        color: var(--dark-brown, #6a451a);
        cursor: pointer;
      }
      .noteDate {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        img {
          margin-right: 15px;
        }
        h4 {
          font-size: 16px;
          font-weight: 900;
          color: var(--gray-1, #333);
        }
        span {
          font-size: 14px;
          font-weight: 500;
          line-height: 24px;
          color: var(--gray-2, #4f4f4f);
        }
      }
    }
  }
  
  .modalContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 410px;
    padding: 40px 38px;
    height: 366px;
    .modalHeader {
      margin-bottom: 38px;
      h1 {
        font-size: 20px;
        font-weight: 900;
        color: var(--gray-1, #333);
      }
      span {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        color: var(--gray-2, #4f4f4f);
      }
    }
    form {
      width: 100%;
      .modalInput {
        width: 100%;
        margin-bottom: 30px;
      }
      button {
        font-size: 16px;
        font-weight: 900;
        color: #fff;
        border: none;
        height: 45px;
        width: 100%;
        background: var(--mobile-primary-color-default-color, #fd9c42);
        border-radius: 6px;
      }
    }
  }
  .information {
    padding: 0px 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    .info:nth-child(odd) {
      width: 45%;
    }
    .info:nth-child(even) {
      width: 45%;
    }
    .info {
      margin-bottom: 32px;
      span {
        font-size: 12px;
        font-weight: 500;
        text-transform: uppercase;
        color: var(--gray-2, #4f4f4f);
      }
      p {
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        color: var(--gray-1, #333);
      }
    }
  }
  .addressInfo {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .street {
    padding: 0px 20px;
    margin-bottom: 10px;
    font-weight: normal;
   
  }
  .subHeader {
    padding: 10px 20px;
    font-size: 18px;
    font-weight: 900;
    color: #b17f34;
    margin: 0;
  }