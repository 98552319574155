.sign-in-layout {
    margin: 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
    background: #f2f2f2;
   
  
  }
  
  .content {
    width: 100vw;
  
    height: 100vh;
  
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transform: scale(1);
   
  }
  
  .terms_footer {
    margin-top: auto;
    background-color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 56px;
    flex-shrink: 0;
  }
  